import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { useNavigation } from '../../context/NavigationContext';
// import "./4TT.css"

const Container = styled.div`
    padding-top: 60px;  // Adjust according to the height of your header
    margin: 0px 70px;
    font-size: 18px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80%
    `;

// margin-left: 300px;
const ButtonNext = styled.button`
    width: 150px;
    padding: 15px 20px;
    border-radius: 6px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    border: none;
    background-color:#1549e1bf;
    font-weight: bold;
    
    &:hover {
        background-color: #1d357dbf;
        color: white;
    }
`

const ButtonPrevious = styled.button`
    width: 150px;
    padding: 15px 20px;
    border-radius: 6px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    border: none;
    font-weight: bold;

    &:hover {
        background-color: #bac1d5bf;
        color: white;
    }
`

// const nput = styled.input``

const Documents = (nextPath) => {
    const { activeIndex, setActiveIndex, menuItems } = useNavigation();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const currentIndex = menuItems.findIndex(item => item.path === location.pathname);
        setActiveIndex(currentIndex);
    }, [location, navigate, menuItems, setActiveIndex]);

    const handleNextClick = () => {
        const nextIndex = (activeIndex + 1) % menuItems.length;
        navigate(menuItems[nextIndex].path);
        setActiveIndex(nextIndex);
    };

    const handlePreviousClick = () => {
        const nextIndex = (activeIndex - 1) % menuItems.length;
        navigate(menuItems[nextIndex].path);
        setActiveIndex(nextIndex);
    };

    return (
        <Container>
            <div className="form-container">
                <h2 style={{ "display": "flex", "justify-content": "center" }}>TÀI LIỆU YÊU CẦU</h2>
                <p>
                    Mỗi nhân viên phải nộp những tài liệu sau đây để hoàn tất hồ sơ nhân viên tại công ty. Bạn cần đọc kỹ và chuẩn bị để nộp tài liệu đúng hạn.
                </p>
                <form action="#" method="post">

                    {/* Trình độ chuyên môn */}
                    <section className="form-section">
                        <div className="form-row">
                            <div>1. Bản sao công chứng của thẻ căn cước/CMND</div>

                        </div>
                    </section>

                    {/* Kinh nghiệm làm việc */}
                    <section className="form-section">
                        <div className="form-row">
                            <div>2. Bằng cấp, chứng chỉ</div>
                        </div>
                    </section>
                    {/* <button type="submit">Submit</button> */}
                </form>
            </div>
            <div>
                <div style={{ display: 'flex', "justify-content": "space-between", "padding-bottom": "50px" }}>
                    <ButtonPrevious onClick={handlePreviousClick} >Bước trước</ButtonPrevious>
                    <ButtonNext onClick={handleNextClick} >Tiếp theo</ButtonNext>
                </div>
            </div>
        </Container>
    );
};

export default Documents;