import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { useNavigation } from '../../context/NavigationContext';
import { useForm } from 'react-hook-form';
// import "./4TT.css"

const Container = styled.div`
    padding-top: 60px;  // Adjust according to the height of your header
    margin: 0px 70px;
    font-size: 18px;
    width: 100%;
    `;

// margin-left: 300px;
const ButtonNext = styled.button`
    width: 150px;
    padding: 15px 20px;
    border-radius: 6px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    border: none;
    background-color:#1549e1bf;
    font-weight: bold;
    
    &:hover {
        background-color: #1d357dbf;
        color: white;
    }
`

const ButtonPrevious = styled.button`
    width: 150px;
    padding: 15px 20px;
    border-radius: 6px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    border: none;
    font-weight: bold;

    &:hover {
        background-color: #bac1d5bf;
        color: white;
    }
`

// const nput = styled.input``

const Background = (nextPath) => {
    const { activeIndex, setActiveIndex, menuItems } = useNavigation();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const currentIndex = menuItems.findIndex(item => item.path === location.pathname);
        setActiveIndex(currentIndex);
    }, [location, navigate, menuItems, setActiveIndex]);

    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    useEffect(() => {
        const savedData = localStorage.getItem('backgroundData');
        if (savedData) {
            const formData = JSON.parse(savedData);
            for (const [key, value] of Object.entries(formData)) {
                setValue(key, value);
            }
        }
    }, [setValue]);

    const onSubmit = data => {
        localStorage.setItem('backgroundData', JSON.stringify(data))
        const nextIndex = (activeIndex + 1) % menuItems.length;
        setActiveIndex(nextIndex);
        navigate(menuItems[nextIndex].path);
        console.log(data);
        // sendDataToServer(formData);
    };

    const handlePreviousClick = () => {
        const nextIndex = (activeIndex - 1) % menuItems.length;
        navigate(menuItems[nextIndex].path);
        setActiveIndex(nextIndex);
    };

    return (
        <Container>
            <h2 style={{ "display": "flex", "justify-content": "center" }}>THÔNG TIN NỀN TẢNG</h2>
            <p> Để giúp chúng tôi hiểu bạn hơn, chúng tôi muốn bạn điền thông tin về nền tảng của bạn vào các biểu mẫu sau đây. </p>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">

                    {/* Trình độ chuyên môn */}
                    <div><strong>Trình độ chuyên môn</strong> <br></br> Lịch sử học tập của bản thân</div>
                    <section className="form-section">
                        <div className="form-row">
                            <div className="form-group">
                                <label for="school">Trường * </label>
                                <input type="text" {...register("school", { required: "Trường là bắt buộc" })} />
                                {errors.school && <div style={{ color: "red" }}>{errors.school.message}</div>}
                            </div>
                            <div className="form-group">
                                <label for="lastName">Thành phố *</label>
                                <input type="text" {...register("city", { required: "Thành phố là bắt buộc" })} />
                                {errors.city && <div style={{ color: "red" }}>{errors.city.message}</div>}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label for="timeStudy">Thời gian *</label>
                                <input type="text" {...register("timeStudy", { required: "Thời gian là bắt buộc" })} />
                                {errors.timeStudy && <div style={{ color: "red" }}>{errors.timeStudy.message}</div>}
                            </div>
                            <div className="form-group">
                                <label for="lastName">Bằng cấp *</label>
                                <input type="text" placeholder='Đại học' {...register("degree", { required: "Bằng cấp là bắt buộc" })} />
                                {errors.degree && <div style={{ color: "red" }}>{errors.degree.message}</div>}
                            </div>
                        </div>
                    </section>

                    {/* Kinh nghiệm làm việc */}
                    <div><strong> Kinh nghiệm làm việc </strong> <br></br> Danh sách các nơi đã từng làm việc</div>
                    <section className="form-section">
                        <div className="form-row">
                            <div className="form-group">
                                {/* <label for="firstName">Địa chỉ tạm trú * </label> */}
                                <textarea name="mo_ta" rows="8" cols="auto" placeholder="Công ty - Địa điểm - Thời gian - Vị trí " {...register("expWork")}></textarea>
                            </div>
                        </div>
                    </section>
                    {/* <button type="submit">Submit</button> */}
                </div>
                <div>
                    <div style={{ display: 'flex', "justify-content": "space-between", "padding-bottom": "50px" }}>
                        <ButtonPrevious onClick={handlePreviousClick} >Bước trước</ButtonPrevious>
                        <ButtonNext type="submit" >Tiếp theo</ButtonNext>
                    </div>
                </div>
            </form>
        </Container>
    );
};

export default Background;
